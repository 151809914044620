<template>
    <div class="form">
        <h1>Анкета физического лица</h1>

        <div class="form__body">
            <client-block :mname="user.userName.mname" :name="user.userName.name" :sname="user.userName.sname"/>
            <passport-block :department="user.passport.department" :issue="user.passport.issue"
                            :issue-date="user.passport.issueDate"
                            :number="user.passport.number"/>
            <address-block
                    :actual-address="user.actualAddress"
                    :birthday="user.birthday"
                    :birthplace="user.birthplace"
                    :email="user.email"
                    :inn="user.inn"
                    :phone="user.phone"
                    :registration-address="user.registrationAddress"
                    :snils="user.snils.number"
            />
            <div class="block">
                <div class="row">
                    <div class="col-12">
                        <p class="anketa">Сведения о целях установления и предполагаемом характере деловых отношений с
                            ЗАО УК «РВМ
                            Капитал»</p>
                        <rwm-text-field
                                v-model="_GoalsOfEstablishingARelationship"
                                :error="errors.goalsOfEstablishingARelationship"
                                :disabled="disabled"
                                @focus="errors.goalsOfEstablishingARelationship = false"
                                @blur="onSomeChange('goalsOfEstablishingARelationship', ...arguments)"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="anketa">Сведения о целях финансово-хозяйственной деятельности (о планируемых
                            операциях)</p>
                        <div class="group-selecting-line">
                            <rwm-radiobox :value="true" :checked="_Purpose" label="Извлечение прибыли" name="finhoz"
                                          @change="onPurpose" :disabled="disabled"/>
                            <rwm-radiobox :value="false" label="Иное:"
                                          :checked="_Purpose !== null ? !_Purpose : _Purpose" name="finhoz"
                                          @change="onPurpose" :disabled="disabled"/>
                            <rwm-text-field
                                    type="text"
                                    v-model="_PurposeTxt"
                                    :disabled="_Purpose || disabled"
                                    :error="errors.purposeTxt"
                                    @focus="errors.purposeTxt = false"
                                    @blur="onSomeChange('purposeTxt', ...arguments)"
                            />
                        </div>
                    </div>
                    <div class="cok-12">
                        <p class="anketa">Сведения о финансовом положении физического лица</p>
                        <div class="group-selecting-line">
                            <rwm-radiobox :value="true" :checked="_Finpol" label="Удовлетворительное" name="finpol"
                                          @change="onSomeChange('finpol', ...arguments)" :disabled="disabled"/>
                            <rwm-radiobox :value="false" label="Неудовлетворительное"
                                          :checked="_Finpol !== null ? !_Finpol : _Finpol" name="finpol"
                                          @change="onSomeChange('finpol', ...arguments)" :disabled="disabled"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="cok-12">
                        <p class="anketa">Сведения о деловой репутации физического лица</p>
                        <div class="group-selecting-line">
                            <rwm-radiobox :value="true" :checked="_Reputation" label="Удовлетворительное"
                                          name="reputation"
                                          @change="onSomeChange('reputation', ...arguments)" :disabled="disabled"/>
                            <rwm-radiobox :value="false" label="Неудовлетворительное"
                                          :checked="_Reputation !== null ? !_Reputation : _Reputation" name="reputation"
                                          @change="onSomeChange('reputation', ...arguments)" :disabled="disabled"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="anketa">Сведения об источниках происхождения денежных средств и (или) иного имущества
                            физического
                            лица</p>
                        <rwm-text-field
                                v-model="_SourcesOfFunds"
                                :disabled="disabled"
                                @blur="onSomeChange('sourcesOfFunds', ...arguments)"
                        />
                    </div>
                </div>
            </div>
            <div class="block">
                <div class="row">
                    <p class="anketa">Относитесь ли Вы к следующим категориям граждан?</p>
                    <div class="col-12">
                        <div class="group-selecting">
                            <p class="anketa">
                                Иностранные публичные должностные лица или российские публичные должностные лица
                                <rwm-question-mark :text="footnote[0]" footnote-number="1" style="bottom: -5px;"/>
                            </p>
                            <rwm-radiobox :value="true" label="Да" :checked="_CitizenCategoriesDeputat"
                                          label-position="bottom" name="deputat"
                                          @change="onCitizenCategory({deputat: true})"
                                          :disabled="disabled"/>
                            <rwm-radiobox ref="deputat" :value="false"
                                          :checked="_CitizenCategoriesDeputat !== null ? !_CitizenCategoriesDeputat : _CitizenCategoriesDeputat"
                                          label="Нет" label-position="bottom"
                                          name="deputat"
                                          @change="onCitizenCategory({deputat: false})"
                                          :disabled="disabled"/>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="group-selecting">
                            <p class="anketa">
                                Должностные лица публичных международных организаций
                                <rwm-question-mark :text="footnote[1]" footnote-number="2" style="bottom: -5px;"/>
                            </p>
                            <rwm-radiobox :value="true" label="Да" :checked="_CitizenCategoriesMo"
                                          label-position="bottom" name="mo"
                                          @change="onCitizenCategory({mo: true})"
                                          :disabled="disabled"/>
                            <rwm-radiobox ref="mo" :value="false"
                                          :checked="_CitizenCategoriesMo !== null ? !_CitizenCategoriesMo : _CitizenCategoriesMo"
                                          label="Нет" label-position="bottom" name="mo"
                                          @change="onCitizenCategory({mo: false})"
                                          :disabled="disabled"/>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="group-selecting">
                            <p class="anketa">
                                Иные категории граждан
                                <rwm-question-mark :text="footnote[2]" footnote-number="3" style="bottom: -5px;"/>
                            </p>
                            <rwm-radiobox :value="true" label="Да" :checked="_CitizenCategoriesOther"
                                          label-position="bottom" name="other"
                                          @change="onCitizenCategory({other: true})"
                                          :disabled="disabled"/>
                            <rwm-radiobox ref="other" :value="false"
                                          :checked="_CitizenCategoriesOther !==null ? !_CitizenCategoriesOther : _CitizenCategoriesOther"
                                          label="Нет" label-position="bottom"
                                          name="other"
                                          @change="onCitizenCategory({other: false})"
                                          :disabled="disabled"/>
                        </div>
                    </div>
                </div>
                <transition mode="out-in" name="slide">
                    <additional-fields-block
                            v-if="isCategorySelected"
                            :position="_IfCitizenCategoriesPosition"
                            :state="_IfCitizenCategoriesState"
                            :start-date="_IfCitizenCategoriesStartDate"
                            :end-date="_IfCitizenCategoriesEndDate"
                            :error="errors.ifCitizenCategories"
                            @input="setCategory"
                            @removeError="errors.ifCitizenCategories = false"
                            @update="updateCategory"
                            :disabled="disabled"
                    />
                </transition>
            </div>
            <div class="block">
                <div class="row">
                    <div class="col-12">
                        <div class="group-selecting">
                            <p class="anketa">
                                Занимали ли Вы в течение одного года до момента заполнения настоящей анкеты
                                высокопоставленную должность
                                в публичных органах управления иностранного государства?
                            </p>
                            <rwm-radiobox :value="true" label="Да" :checked="_Foreign_politician"
                                          label-position="bottom" name="foreign_politician"
                                          @change="onSomeChange('foreign_politician', ...arguments)"
                                          :disabled="disabled"/>
                            <rwm-radiobox :value="false"
                                          :checked="_Foreign_politician !== null ? !_Foreign_politician : _Foreign_politician"
                                          label="Нет" label-position="bottom"
                                          name="foreign_politician"
                                          @change="onSomeChange('foreign_politician', ...arguments)"
                                          :disabled="disabled"/>
                        </div>
                    </div>
                </div>
                <transition mode="out-in" name="slide">
                    <additional-fields-block
                            v-if="_Foreign_politician"
                            :position="_IfForeign_politicianPosition"
                            :state="_IfForeign_politicianState"
                            :start-date="_IfForeign_politicianStartDate"
                            :end-date="_IfForeign_politicianEndDate"
                            :error="errors.ifForeign_politician"
                            @input="setPolitician"
                            @removeError="errors.ifForeign_politician = false"
                            @update="updateForeignPolitician"
                            :disabled="disabled"
                    />
                </transition>
            </div>
            <div class="block">
                <div class="row">
                    <div class="col-12">
                        <div class="group-selecting">
                            <p class="anketa">
                                Являетесь ли Вы усыновителем лица (усыновленным лицом), относящегося (относящимся) к
                                категории граждан,
                                указанной выше в настоящей анкете?
                            </p>
                            <rwm-radiobox :value="true" label="Да" :checked="_Relative" label-position="bottom"
                                          name="relative"
                                          @change="onSomeChange('relative', ...arguments)"
                                          :disabled="disabled"/>
                            <rwm-radiobox :value="false" :checked="_Relative !== null ? !_Relative : _Relative"
                                          label="Нет" label-position="bottom" name="relative"
                                          @change="onSomeChange('relative', ...arguments)"
                                          :disabled="disabled"/>
                        </div>

                    </div>
                </div>
            </div>
            <div class="block" style="border-bottom: none">
                <div class="row">
                    <div class="col-12 relatives">
                        <p class="anketa" style="margin-bottom: 0">Укажите Ваших супруга (супругу), близких
                            родственников по прямой, восходящей и нисходящей
                            линии:</p>
                        <information-about-relatives
                                v-for="(relative, index) in _Relations"
                                :key="`relations-${index}`"
                                :number="index"
                                :kinship="relative.kinship"
                                :fio="relative.fio"
                                :birthdate="relative.birthDate"
                                :residence="relative.residence"
                                :seria="relative.passportSeria"
                                :num="relative.passportNumber"
                                :department="relative.passportDepartment"
                                :issue-date="relative.passportIssueDate"
                                :issued-by="relative.passportIssueBy"
                                :error="errors.ifRelations"
                                @input="setRelations(index, ...arguments)"
                                @formInput="isDataValid = false"
                                @update="updateRelations"
                                @delete="deleteRelative"
                                :disabled="disabled"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <rwm-button outlined width="100%" @click="addRelative" :disabled="disabled">
                            <v-icon :hovering="false" size="28px" style="margin-right: 14px">plus</v-icon>
                            Добавить
                        </rwm-button>
                    </div>
                </div>
                <div class="row">
                    <rwm-checkbox
                            label="Сведения, указанные в настоящей анкете, подтверждаю.<br>В случае изменения указанных в настоящей анкете сведений обязуюсь новые сведения сообщить в ЗАО УК «РВМ Капитал»."
                            @change="iConfirmMyData"
                            v-model="isDataValid"
                            :disabled="disabled"
                    />
                </div>
                <div class="row">
                    <div class="col-3">
                        <rwm-button width="100%" @click="submit" :disabled="!readyToSubscribe">Подписать</rwm-button>
                    </div>
                </div>
                <rwm-sms-checking v-model="_timer" :isShow="showChecking" :time="60" @sendAgain="sendAgain()"
                                  @verified="codeIsVerified"/>
            </div>
            <rwm-footnote :footnotes="footnote" />
        </div>
    </div>
</template>

<script>
    import ClientBlock from "./blocks/ClientBlock";
    import PassportBlock from "./blocks/PassportBlock";
    import AddressBlock from "./blocks/AddressBlock";
    import RwmTextField from "../../../../RWMTextField/RwmTextField";
    import RwmRadiobox from "../../../../RWMRadioBox/RwmRadioBox";
    import AdditionalFieldsBlock from "./blocks/AdditionalFieldsBlock";
    import InformationAboutRelatives from "./blocks/informationAboutRelatives";
    import RwmButton from "../../../../RWMButton/RwmButton";
    import VIcon from "../../../../icons/VIcon";
    import RwmCheckbox from "../../../../RWMCheckbox/RwmCheckbox";
    import RwmSmsChecking from "../../../../RWMSMSChecking/RwmSmsChecking";
    import RwmQuestionMark from "../../../../RWMQutestionMark/RwmQuestionMark";
    import RwmFootnote from "../../../../RWMFootnote/RwmFootnote";

    export default {
        name: "PodFt",
        components: {
            RwmFootnote,
            RwmQuestionMark,
            RwmSmsChecking,
            RwmCheckbox,
            VIcon,
            RwmButton,
            InformationAboutRelatives,
            AdditionalFieldsBlock, RwmRadiobox, RwmTextField, AddressBlock, PassportBlock, ClientBlock
        },
        props: {
            user: Object,
            timer: {
                type: Boolean,
                default: false
            },
            showChecking: {
                type: Boolean,
                default: false
                // required: true
            },
            disabled: Boolean,
            PropGoalsOfEstablishingARelationship: String,
            PropPurpose: Boolean,
            PropPurposeTxt: String,
            PropFinpol: Boolean,
            PropReputation: Boolean,
            PropSourcesOfFunds: String,
            PropCitizenCategoriesDeputat: Boolean,
            PropCitizenCategoriesMo: Boolean,
            PropCitizenCategoriesOther: Boolean,
            PropIfCitizenCategoriesPosition: String,
            PropIfCitizenCategoriesState: String,
            PropIfCitizenCategoriesStartDate: [String, Number],
            PropIfCitizenCategoriesEndDate: [String, Number],
            PropForeign_politician: Boolean,
            PropIfForeign_politicianPosition: String,
            PropIfForeign_politicianState: String,
            PropIfForeign_politicianStartDate: [String, Number],
            PropIfForeign_politicianEndDate: [String, Number],
            PropRelative: Boolean,
            PropRelations: Array,
        },
        computed: {
            _timer: {
                get() {
                    return this.timer
                },
                set(val) {
                    this.$emit('timer', val)
                }
            },
            isCategorySelected() {
                let result = false
                if (this.PropCitizenCategoriesDeputat || this.PropCitizenCategoriesMo || this.PropCitizenCategoriesOther) {
                  result = true
                  this.migrateCategoriesData()
                }
                return result
            },
            _GoalsOfEstablishingARelationship: {
                get() {
                    return this.PropGoalsOfEstablishingARelationship
                },
                set(val) {
                    this.goalsOfEstablishingARelationship = val
                }
            },
            _Purpose: {
                get() {
                    return this.PropPurpose
                },
                set(val) {
                    this.purpose = val
                }
            },
            _PurposeTxt: {
                get() {
                    return this.PropPurposeTxt
                },
                set(val) {
                    this.purposeTxt = val
                }
            },
            _Finpol: {
                get() {
                    return this.PropFinpol
                },
                set(val) {
                    this.finpol = val
                }
            },
            _Reputation: {
                get() {
                    return this.PropReputation
                },
                set(val) {
                    this.reputation = val
                }
            },
            _SourcesOfFunds: {
                get() {
                    return this.PropSourcesOfFunds
                },
                set(val) {
                    this.sourcesOfFunds = val
                }
            },
            _CitizenCategoriesDeputat: {
                get() {
                    return this.PropCitizenCategoriesDeputat
                },
                set(val) {
                    this.citizenCategories.deputat = val
                }
            },
            _CitizenCategoriesMo: {
                get() {
                    return this.PropCitizenCategoriesMo
                },
                set(val) {
                    this.citizenCategories.mo = val
                }
            },
            _CitizenCategoriesOther: {
                get() {
                    return this.PropCitizenCategoriesOther
                },
                set(val) {
                    this.citizenCategories.other = val
                }
            },
            _IfCitizenCategoriesPosition: {
                get() {
                    return this.PropIfCitizenCategoriesPosition
                },
                set(val) {
                    this.ifCitizenCategories.position = val
                }
            },
            _IfCitizenCategoriesState: {
                get() {
                    return this.PropIfCitizenCategoriesState
                },
                set(val) {
                    this.ifCitizenCategories.state = val
                }
            },
            _IfCitizenCategoriesStartDate: {
                get() {
                    return this.PropIfCitizenCategoriesStartDate
                },
                set(val) {
                    this.ifCitizenCategories.startDate = val
                }
            },
            _IfCitizenCategoriesEndDate: {
                get() {
                    return this.PropIfCitizenCategoriesEndDate
                },
                set(val) {
                    this.ifCitizenCategories.endDate = val
                }
            },
            _Foreign_politician: {
                get() {
                    return this.PropForeign_politician
                },
                set(val) {
                    this.foreign_politician = val
                }
            },
            _IfForeign_politicianPosition: {
                get() {
                    return this.PropIfForeign_politicianPosition
                },
                set(val) {
                    this.ifForeign_politician.position = val
                }
            },
            _IfForeign_politicianState: {
                get() {
                    return this.PropIfForeign_politicianState
                },
                set(val) {
                    this.ifForeign_politician.state = val
                }
            },
            _IfForeign_politicianStartDate: {
                get() {
                    return this.PropIfForeign_politicianStartDate
                },
                set(val) {
                    this.ifForeign_politician.startDate = val
                }
            },
            _IfForeign_politicianEndDate: {
                get() {
                    return this.PropIfForeign_politicianEndDate
                },
                set(val) {
                    this.ifForeign_politician.endDate = val
                }
            },
            _Relative: {
                get() {
                    return this.PropRelative
                },
                set(val) {
                    this.relative = val
                }
            },
            _Relations: {
                get() {
                    return this.PropRelations
                },
                set(val) {
                    this.relations = val
                }
            },
        },
        watch: {
            isDataValid(val){
                if(val !== true){
                    this.readyToSubscribe = false;
                }
            }
        },
        data() {
            return {
                footnote: [
                    "Сведения, указанные в настоящем пункте, устанавливаются в отношении иностранных граждан и лиц без гражданства, находящихся на территории Российской Федерации, в случае если необходимость наличия у них документа, подтверждающего право иностранного гражданина или лица без гражданства на пребывание (проживание) в Российской Федерации, предусмотрена законодательством Российской Федерации.",
                    "Иностранное публичное должностное лицо – любое назначаемое или избираемое лицо, занимающее какую-либо должность в законодательном, исполнительном, административном или судебном органе иностранного государства, и любое лицо, выполняющее какую-либо функцию для иностранного государства, в том числе для публичного ведомства или публичного предприятия.",
                    "Лица, замещающие (занимающие) государственные должности Российской Федерации, должности членов Совета директоров Центрального банка Российской Федерации, должности федеральной государственной службы, назначение на которые и освобождение от которых осуществляются Президентом Российской Федерации или Правительством Российской Федерации, должности в Центральном банке Российской Федерации, государственных корпорациях и иных организациях, созданных Российской Федерацией на основании федеральных законов, включенные в перечни должностей, определяемые Президентом Российской Федерации."
                ],
                braking: false,
                errors: {
                    goalsOfEstablishingARelationship: false,
                    purposeTxt: false,
                    citizenCategories: false,
                    ifCitizenCategories: false,
                    foreign_politician: false,
                    ifForeign_politician: false,
                    reputation: false,
                    finpol: false,
                    ifRelations: false,
                },
                goalsOfEstablishingARelationship: "",
                purpose: true,
                purposeTxt: "",
                finpol: true,
                reputation: true,
                sourcesOfFunds: "",
                citizenCategories: {
                    deputat: false,
                    mo: false,
                    other: false
                },
                ifCitizenCategories: {
                    position: "",
                    state: "",
                    startDate: "",
                    endDate: ""
                },
                foreign_politician: false,
                ifForeign_politician: {
                    position: "",
                    state: "",
                    startDate: "",
                    endDate: ""
                },
                relative: false,
                relations: [],
                ifRelations: {
                    kinship: "",
                    fio: "",
                    birthDate: "",
                    residence: "",
                    passportSeria: "",
                    passportNumber: "",
                    passportIssueDate: "",
                    passportDepartment: "",
                    passportIssueBy: "",
                },
                isDataValid: false,
                readyToSubscribe: false
            }
        },

        methods: {
            // for working with relations array
            addRelative() {
                this.isDataValid = false
                this.relations.push({...this.ifRelations})
                this.updateRelations()
            },
            deleteRelative(index) {
                this.isDataValid = false
                this.relations = this.PropRelations
                if (this.PropRelations.length === 1) {
                    this.relations = []
                } else this.relations.splice(index, 1)
                this.updateRelations()
            },
            setRelations(index, category) {
                if (this.relations.length === 0 && this.PropRelations.length === 0) {
                    this.relations.push(this.ifRelations)
                } else this.relations = this.PropRelations
                this.relations[index][category.category] = category.value
            },
            updateRelations() {
                this.isDataValid = false
                if (this.relations.length >= 0) {
                    this.$emit('update', {'relations': this.relations})
                }
            },

            // onFundsInput() {
            //     this.isDataValid = false;
            //     this.errors.sourcesOfFunds = false
            // },

            migrateCategoriesData() {
                this.ifCitizenCategories.position = this.PropIfCitizenCategoriesPosition
                this.ifCitizenCategories.state = this.PropIfCitizenCategoriesState
                this.ifCitizenCategories.startDate = this.PropIfCitizenCategoriesStartDate
                this.ifCitizenCategories.endDate = this.PropIfCitizenCategoriesEndDate

                this.ifForeign_politician.position = this.PropIfForeign_politicianPosition
                this.ifForeign_politician.state = this.PropIfForeign_politicianState
                this.ifForeign_politician.startDate = this.PropIfForeign_politicianStartDate
                this.ifForeign_politician.endDate = this.PropIfForeign_politicianEndDate
            },
            setCategory(category) {
                this.isDataValid = false;
                this[`_IfCitizenCategories${category.category.charAt(0).toUpperCase() + category.category.slice(1)}`] = category.value
            },
            setPolitician(category) {
                this.isDataValid = false;
                this[`_IfForeign_politician${category.category.charAt(0).toUpperCase() + category.category.slice(1)}`] = category.value
                // this.ifForeign_politician[category.category] = category.value
            },
            checkFullness(category) {
                for (let key in this[category]) {
                    if (this[category][key] && this[category][key].length === 0) {
                        this[category][key]=this[`${category.replace('if', 'PropIf')}${key.charAt(0).toUpperCase()}${key.slice(1)}`]
                    }
                }
            },
            updateCategory() {
                this.checkFullness('ifCitizenCategories')
                this.$emit('update', {'ifCitizenCategories': this.ifCitizenCategories})
            },
            updateForeignPolitician() {
                this.checkFullness('ifForeign_politician')
                this.$emit('update', {'ifForeign_politician': this.ifForeign_politician})
            },

            onCitizenCategory(val) {
                this.isDataValid = false
                for (let key in val) {
                    this.citizenCategories[key] = val[key]
                    this.$emit('update', {citizenCategories: {[key]: this.citizenCategories[key]}})
                }
            },

            onPurpose(val) {
                this.isDataValid = false
                this.purpose = val
                if (val) {
                    setTimeout(() => {
                        this.purposeTxt = ""
                        this.errors.purposeTxt = false;
                        this.$emit('update', {'purposeTxt': this.purposeTxt})
                    }, 150)
                }
                this.$emit('update', {'purpose': this.purpose})
            },

            onSomeChange(key, val) {
                if (typeof(val) === 'object') {
                    this[key] = val.target.value
                    if (Object.keys(this.errors).includes(key) && val.target.value.length === 0) this.errors[key] = true
                } else this[key] = val
                this.isDataValid = false
                this.$emit('update', {[key]: this[key]})
            },

            // validation of all data in this form
            propKeyToErrorKey(key) {
                let rep = key.replace('_', '')
                return rep.charAt(0).toLowerCase() + rep.slice(1)
            },
            validateForm() {
                let keys = ["_GoalsOfEstablishingARelationship", "_Finpol", "_Reputation", "_PurposeTxt", "_IfCitizenCategories", "_IfForeign_politician", "_IfRelations"]
                for (let key of keys) {
                    if (typeof(this[key]) === "string") {
                        this.errors[this.propKeyToErrorKey(key)] = this[key].trim().length === 0
                    }
                    if (key === "_PurposeTxt") {
                        this.PropPurpose
                            ? this.errors[this.propKeyToErrorKey(key)] = false : !this[key] || this[key].trim().length === 0
                                ? this.errors[this.propKeyToErrorKey(key)] = true : this.errors[this.propKeyToErrorKey(key)] = false
                    }
                    if (key === "_IfForeign_politician") {
                        if (!this._Foreign_politician) {
                            this.errors[this.propKeyToErrorKey(key)] = false
                        } else {
                            this.validateSubErrors(key)
                        }
                    }
                    if (key === "_IfCitizenCategories") {
                        if (!this.isCategorySelected) {
                            this.errors[this.propKeyToErrorKey(key)] = false
                        } else {
                            this.validateSubErrors(key)
                        }
                    }
                    if (key === "_IfRelations") {
                        if (this.relations.length === 0) {
                            this.errors[this.propKeyToErrorKey(key)] = false
                        } else {
                            this.relations.forEach(element => {
                                let trigger = false
                                for (let el in element) {
                                    if (element[el].trim().length === 0) {
                                        trigger = true
                                        break
                                    }
                                }
                                this.errors[this.propKeyToErrorKey(key)] = trigger
                            })
                        }
                    }
                }
                let validation = true
                for (let el in this.errors) {
                    if (this.errors[el]) {
                        validation = false
                    }
                }
                return validation
            },
            validateSubErrors(key) {
                let trigger = false
                for (let el in this[this.propKeyToErrorKey(key)]) {
                    if (this[this.propKeyToErrorKey(key)][el].trim().length === 0) {
                        trigger = true
                        break
                    }
                }
                this.errors[this.propKeyToErrorKey(key)] = trigger
            },

            // when user confirmed all his data, we need to validate them and if it`s good, undisable the subscribe button
            iConfirmMyData(val) {
                if (val) {
                    this.readyToSubscribe = this.validateForm() && this.isDataValid
                    if (!this.readyToSubscribe) this.$toast.error('Все поля обязательны для заполнения')
                }
            },

            // methods for submit changes and signup form
            codeIsVerified(code) {
                this.$emit('verified', code)
            },
            sendAgain() {
                this.$emit('sendAgain')
            },
            submit() {
                this.$emit('submit', {})
            },
        }
    }
</script>

<style lang="scss" scoped>
    .form {
        text-align: left;

        & h1 {
            margin-bottom: 32px;
        }

        &__body {
            display: flex;
            flex-direction: column;
            row-gap: 32px;

            & .block {
                display: flex;
                flex-direction: column;
                row-gap: 24px;

                &:after {
                    content: "";
                    opacity: 0.5;
                    border: 1px solid $RWM-grey-light;
                }

                &:last-child {
                    &:after {
                        border: none;
                    }
                }

                & .row {
                    row-gap: 24px;

                    & .relatives {
                        row-gap: 24px;
                        display: flex;
                        flex-direction: column;
                    }
                }

                & .group-selecting {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    column-gap: 44px;
                    row-gap: 24px;
                    @media screen and (min-width: 1024px) {
                        flex-wrap: nowrap;
                        justify-content: initial;
                        align-items: center;
                    }


                    & .rwm-text-field {
                        flex: 1 1 auto;
                    }
                }

                & .group-selecting-line {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    column-gap: 44px;
                    row-gap: 24px;
                    @media screen and (max-width: 770px) {
                        justify-content: space-between;
                    }
                    @media screen and (min-width: 1024px) {
                        flex-wrap: nowrap;
                        justify-content: initial;
                        align-items: center;
                    }


                    & .rwm-text-field {
                        flex: 1 1 auto;
                    }
                }

                & .anketa {
                    margin-bottom: 12px;
                    flex: 1 1 100%;
                    @media screen and (min-width: 1024px) {
                        flex: 1 1 auto;
                    }
                }
            }
        }
    }
</style>
